$primary: #f5f5f5;
$primary-light: #fff;
$primary-dark: #bcbcbc;
$primary-text: #424242;
$secondary: #f06292;
$secondary-light: #ff94c2;
$secondary-dark: #ba2d65;
$secondary-text: #fce4ec;


.App {
  text-align: center;
  background-color: $primary;
  color: $primary-text;
  min-height: 100vh;
  padding: 2rem 1.5rem;
  line-height: 1.5rem;
  position: relative;
}

p {
  // margin: 0.5rem 0;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
  fill: $secondary !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: $primary-text;
}

.App-header {
  
  // min-height: 50vh;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // font-size: calc(10px + 2vmin);
  
  
}

a {
  color: $secondary;
  
  &:visited {
    color: $secondary-dark;
  }

}

@keyframes App-logo-spin {
  from {
    transform: rotate( 0deg );;
  }
  to {
    transform: rotate( 360deg );
  }
}


.curriculums {
  text-align: left;

  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  > .course {
    border: solid $primary-dark 1px;
    border-radius: 0.8rem;
    padding: 1.2rem 1rem;
    margin-bottom: 2rem;

    > header {
      font-size: 1.5rem;
      font-weight: bold;
      color: $primary-text;
      // display: flex;
      // align-items: center;
      position: relative;
      .course-badge {
        // margin-top: 0.35rem;

        position: absolute;
        top: -2.2rem;
        right: 0px;
      }
    }

    &.coming-soon {
      cursor: not-allowed;
      opacity: 0.7;
      .course-badge {
        background: $secondary-dark;
      }
    }


    .chapters {
      

      .chapter {
        border-top: 2px dashed;
        border-color: $secondary !important;
        margin:0 1rem; 
        padding: 2rem;
        counter-increment: section;
        position: relative;

        display: block;

        &:before {
          content: counter(section);
          position: absolute;
          border-radius: 50%;
          padding: 8px 10px 11px;
          height: 20px;
          width: 20px;
          background-color: $secondary;
          text-align:center;
          color: $secondary-text;
          font-size: 110%;
          margin-top: -0.5rem;
          font-weight: bold;
        }
        &:nth-child(even):before {
          right: 100%; 
          margin-right: -20px;
        }

        &:nth-child(odd):before {
          left: 100%; 
          margin-left: -20px;
        }


        
        &:nth-child(even) {
          border-left: 2px dashed;
          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;
          margin-right: calc(1rem + 30px); 
          padding-right: 0;
        }

        &:nth-child(odd) {
          border-right: 2px dashed;
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
          margin-left: calc(1rem + 30px); 
          padding-left: 0;
        }

        &:first-of-type {
          border-top: 0;
          border-top-right-radius:0;
          border-top-left-radius:0;
        }

        &:last-of-type {
          border-bottom-right-radius:0;
          border-bottom-left-radius:0;
        }


        > header {
          font-weight: bold;
          color: $secondary;
          &:hover {
            color: $secondary-dark;
          }
        }

        > article {
          font-size: 0.98rem;
        }

        > article {
          margin-top: 1rem;
        }
      }


      
    }
  }
}

.course-badge {
  font-size: 0.6rem;
  display: inline-block;
  padding: 0.2rem 0.8rem 0.25rem;
  // margin-right: 0.5rem;
  background: $secondary;
  color: $secondary-text;
  font-weight: bold;
  border-radius: 1rem;
  flex-shrink: 0;
  flex-grow: 0;
  align-self: flex-start;
}

footer {
  position: fixed;
  bottom: -0.5rem;
  right: 1rem;
  img {
    width: 15vw;
    max-width: 100px;
    cursor: pointer;

    &.question {
      margin-bottom: -10rem;
      transition: margin-bottom 1s;
      &.show {
        margin-bottom: 4rem;
      }
    }
  }
}

#sponsorBadge {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  font-size: 0.5rem;
  
  color: $primary-text;
  // font-weight: bold;

  span {
    font-size: 0.2rem;
  }
  img {
    width: 12px;
    margin-right: 0.05rem;
  }
}